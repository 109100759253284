import React from 'react'
import { Link } from 'gatsby'

import carouselOne from '/static/images/use-case/by-industry/gaming_feature_1.webp'
import carouselTwo from '/static/images/use-case/by-industry/gaming_feature_2.webp'
import carouselThree from '/static/images/use-case/by-industry/gaming_feature_3.webp'
import carouselFour from '/static/images/use-case/by-industry/gaming_feature_4.webp'
import carouselFive from '/static/images/use-case/by-industry/gaming_feature_5.webp'
import { clickMessageBirdChat } from 'utils'

export const gamingFeatures = [
  {
    title: 'Deliver seamless gaming experiences',
    content: `Mlytics ensures your global users enjoy a lag-free experience with our AI-driven platform. Lightning-fast performance and seamless play are guaranteed, no matter where your players are located.`,
    imageSrc: carouselOne,
    imageAlt: 'Deliver seamless gaming experiences',
    children: (
      <button onClick={clickMessageBirdChat} className="bg-none h5-subtext-medium text-blue-100 underline my-3 w-fit">
        Learn more.
      </button>
    )
  },
  {
    title: 'Hyper-fast game downloads worldwide',
    content: `For gamers, ultra-fast downloads are essential. Mlytics’ robust infrastructure, powered by over 300 nodes and real-user monitoring from 30,000 global points, ensures smooth, lightning-fast delivery. By connecting to multiple networks, we boost your game download speeds, providing a seamless experience for your players.`,
    imageSrc: carouselTwo,
    imageAlt: 'Hyper-fast game downloads worldwide',
    children: (
      <Link
        to="/use-case/hyper-speed-files-delivery"
        class="block h5-subtext-medium text-blue-100 underline py-3 w-fit"
      >
        Learn more.
      </Link>
    )
  },
  {
    title: 'Smart routing strategies for peak performance',
    content: `Handle traffic surges and unpredictable demand with Mlytics’ Smart Routing. Our customizable routing logic optimizes each decision for smooth game delivery, ensuring optimal performance during critical game launches or peak times.`,
    imageSrc: carouselThree,
    imageAlt: 'Smart routing strategies for peak performance',
    children: (
      <button className="bg-secondary-600 rounded my-3">
        <Link to="/contact-us" className="block w-full h-full py-2 px-3 text-white">
          See demo
        </Link>
      </button>
    )
  },
  {
    title: 'Optimized costs, maximized performance',
    content: `Mlytics’ smart routing strategies and advanced delivery do more than enhance performance—they help you save costs. Our platform optimizes game performance while minimizing expenses, especially when catering to global players. By streamlining downloads and operations, Mlytics can reduce overall costs by up to 30%, ensuring a balance between high performance and cost efficiency.`,
    imageSrc: carouselFour,
    imageAlt: 'Optimized costs, maximized performance',
    children: (
      <Link to="/contact-us" class="block h5-subtext-medium text-blue-100 underline py-3 w-fit">
        Learn more.
      </Link>
    )
  },
  {
    title: 'Reliable game application security',
    content: `Security is built into the core of Mlytics. Our 24/7 Security Operations Center (SOC) proactively mitigates threats, protecting your game applications from DDoS attacks and other security risks. In the event of an attack, Mlytics’ SOC intelligence system automatically reroutes traffic to prevent downtime, ensuring your game is always online and secure.`,
    imageSrc: carouselFive,
    imageAlt: 'Reliable game application security',
    children: (
      <Link to="/contact-us" class="block h5-subtext-medium text-blue-100 underline py-3 w-fit">
        Learn more.
      </Link>
    )
  }
]
