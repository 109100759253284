import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import Cover from 'components/common/cover'
import UseCase from 'components/common/useCase'
import ContentCarousel from 'components/common/contentCarousel'
import gaming from '/static/images/use-case/by-industry/gaming_hero.webp'
import { gamingFeatures } from '/static/data/use-case/gaming.static'

const Gaming = () => {
  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: `Use Case: Gaming - Mlytics`,
            metaDescription: `In the global gaming market, Mlytics optimizes applications for ultra-fast load times and flawless performance worldwide, ensuring consistent, high-quality experiences for players everywhere.`,
            metaUrl: 'www.mlytics.com/use-case/gaming',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section
          style={{
            height: 'calc(100vh - 100px)',
            minHeight: '600px'
          }}
          className="px-9 md:px-[86px] mb-5 lg:max-h-[800px] base:absolute base:left-1/2 base:-translate-x-1/2 base:w-screen base:max-w-[1920px]"
        >
          <Cover
            smallTitle="Gaming"
            title="Redefine the Power of Gaming Applications."
            imgSrc={gaming}
            imgAlt="Gaming Hero Picture"
            paragraphContent="In a global gaming market, your players expect fast, uninterrupted gameplay. Mlytics optimizes gaming applications, ensuring ultra-fast load times and flawless performance worldwide. Our platform guarantees consistent, high-quality experiences, no matter where your players are."
            actionLinkOne="chatbot"
            actionTextOne="Let’s discuss your use case"
          />
        </section>
        <div className="hidden mb-5 lg:max-h-[800px] base:block base:h-[calc(100vh-100px)] base:min-h-[600px]"></div>
        <section className="py-[60px] px-9 md:px-[86px]">
          <div className="w-full flex flex-col gap-10">
            <ContentCarousel listItem={gamingFeatures} />
          </div>
        </section>
        <section className="py-[60px]">
          <UseCase />
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default Gaming

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
